import React, { useContext } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import {
  Drawer,
  List,
  ListItemText,
  Typography,
  Box,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginPage from "./pages/LoginPage";
import Spoofer from "./pages/Spoofer";
import SpooferLog from "./pages/SpooferLog";
import LogoutHandler from "./components/LogoutHandler";
import PrivateRoute from "./components/PrivateRoute";
import { AuthContext } from "./authContext";

const drawerWidth = 240;

const App: React.FC = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <BrowserRouter>
      <Box sx={{ display: "flex" }}>
        {isLoggedIn && (
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <Box
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                padding: 2,
                textAlign: "center",
              }}
            >
              <Typography variant="h6">Admin Panel</Typography>
            </Box>
            <List sx={{ flexGrow: 1 }}>
              <ListItemButton component={Link} to="/spoofer">
                <ListItemIcon>
                  <MailIcon />
                </ListItemIcon>
                <ListItemText primary="Почта" />
              </ListItemButton>
              <ListItemButton component={Link} to="/spoofer-log">
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary="Журнал почты" />
              </ListItemButton>
            </List>
            <ListItemButton
              component={Link}
              to="/logout"
              sx={{
                position: "absolute",
                bottom: 0,
                width: "100%",
              }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Выход" />
            </ListItemButton>
          </Drawer>
        )}
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/spoofer" element={<PrivateRoute component={Spoofer} />} />
            <Route path="/spoofer-log" element={<PrivateRoute component={SpooferLog} />} />
            <Route path="/logout" element={<LogoutHandler />} />
          </Routes>
        </Box>
      </Box>
    </BrowserRouter>
  );
};

export default App;