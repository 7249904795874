// components/LogoutHandler.tsx
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../authContext";

const LogoutHandler: React.FC = () => {
  const navigate = useNavigate();
  const { handleLogout } = useContext(AuthContext);

  useEffect(() => {
    handleLogout();
    navigate('/'); // Перенаправлять на страницу входа
  }, [navigate, handleLogout]);

  return null; // Компонент не отображает UI
};

export default LogoutHandler;