import React, { useEffect, useState, useContext } from "react";
import { Button, TextField, Box, Typography, Snackbar, Alert } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../authContext'; // Импортируем AuthContext

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate(); // Инициализация
  const { login } = useContext(AuthContext); // Используем AuthContext

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Проверка логина и пароля
    if (username === process.env.REACT_APP_ADMIN_USERNAME && password === process.env.REACT_APP_ADMIN_PASSWORD) {
      login();
      navigate('/spoofer'); // Перенаправляем на главную страницу после логина
    } else {
      setError("Неверный логин или пароль");
    }
  }

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    const loginTime = new Date(localStorage.getItem('loginTime') || '');
    const currentTime = new Date();
    const hoursPassed = (currentTime.getTime() - loginTime.getTime()) / 36e5; // hours passed

    if (isLoggedIn && hoursPassed < 24) {
      navigate('/spoofer'); // Если пользователь все еще в системе, перенаправить на главную
    } else {
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('loginTime');
    }
  }, [navigate]);

  const handleCloseError = () => {
    setError(null);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Black Tower
      </Typography>
      <Box component="form" onSubmit={handleLogin} width="100%" maxWidth={360}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
        >
          Войти
        </Button>
      </Box>
      {error && (
        <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
          <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default LoginPage;