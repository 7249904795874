import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";

interface Log {
  timestamp: string;
  email: string;
  status: string;
}

const SpooferLogs: React.FC = () => {
  const [logs, setLogs] = useState<Log[]>([]);
  const [error, setError] = useState<string | null>(null);

  const fetchLogs = async () => {
    try {
      const response = await axios.get<Log[]>("https://blacktower273.com/logs");
      setLogs(response.data);
    } catch (error) {
      setError("Ошибка при загрузке журналов");
      console.error("Error fetching logs:", error);
    }
  };

  useEffect(() => {
    fetchLogs();
    const interval = setInterval(() => {
      fetchLogs();
    }, 10000); // Обновляем каждые 10 секунд

    return () => clearInterval(interval); // Очищаем интервал при размонтировании компонента
  }, []);

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", mt: 4 }}>
      {error && <Typography color="error">{error}</Typography>}
      <Button variant="contained" color="primary" onClick={fetchLogs} sx={{ mb: 2 }}>
        Обновить
      </Button>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Обновление происходит автоматически каждые 10 секунд. Для ручного обновления нажмите "Обновить".
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Получатель</TableCell>
            <TableCell>Время</TableCell>
            <TableCell>Статус</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.map((log, index) => (
            <TableRow key={index}>
              <TableCell>{log.email}</TableCell>
              <TableCell>{log.timestamp}</TableCell>
              <TableCell>{log.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default SpooferLogs;